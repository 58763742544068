p.para {
    color: #000000;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
}

.ratingmain span img {
    width: 100% !important;
    height: 100% !important;
}
.menunpage-scroll {
    height: calc(100vh - 180px);
    overflow: auto;
}
.menunpage-scroll.bottom-class .slider-container {
   
    top: 0;
    background: #fff;
    z-index: 999;
    transition: all 0.3s;
}

.slider-container {
    background: #fff;
    transition: all 0.5s;
    position: sticky;
    top: -100%;
    overflow: hidden;
}