@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/*********************  Default-CSS  *********************/

input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
}

input[type="file"]::-moz-file-upload-button {
    cursor: pointer;
}

input[type="file"]::-ms-file-upload-button {
    cursor: pointer;
}

input[type="file"]::-o-file-upload-button {
    cursor: pointer;
}

input[type="file"],
a[href],
input[type="submit"],
input[type="button"],
input[type="image"],
label[for],
select,
button,
.pointer {
    cursor: pointer;
}

::-moz-focus-inner {
    border: 0px solid transparent;
}

::-webkit-focus-inner {
    border: 0px solid transparent;
}

*::-moz-selection {
    color: #fff;
    background: #000;
}

*::-webkit-selection {
    color: #fff;
    background: #000;
}

*::-webkit-input-placeholder {
    color: #333333;
    opacity: 1;
}

*:-moz-placeholder {
    color: #333333;
    opacity: 1;
}

*::-moz-placeholder {
    color: #333333;
    opacity: 1;
}

*:-ms-input-placeholder {
    color: #333333;
    opacity: 1;
}

.clr:after,
ul:after,
.clearfix:after,
li:after,
.grve-container:after {
    clear: both;
    display: block;
    content: "";
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
}

.form-control:focus {
    box-shadow: none !important;
}

html body {
    font-family: "Inter", sans-serif;
    margin: 0;
    background: #F7F7F7;
    line-height: 1.3;
}

a,
span,
div a:hover,
div a:active,
button {
    text-decoration: none;
}

*::after,
*::before,
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    outline: none !important;
}

div input,
div select,
div textarea,
div button {
    font-family: "Inter", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    font-family: "Inter", sans-serif;
    line-height: 120%;
    color: #fff;
    font-weight: bold;
    margin: 0 0 15px;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
    margin-bottom: 0;
}

img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

body p {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    line-height: 140%;
    margin: 0 0 15px;
    padding: 0;
}

body p:empty {
    margin: 0;
    line-height: 0;
}

body p:last-child {
    margin-bottom: 0;
}

p strong {
    font-weight: bold;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


/*********************  Default-CSS close  *********************/

a {
    transition: all 0.5s;
}

ul.leftsidemenu a:hover {
    color: black;
}

.logo a:hover {
    background: white;
}

a.btn-green.btnnews:after {
    width: 20px;
    height: 100%;
    background: white;
    content: "";
    position: absolute;
    left: -60px;
    top: 0;
    filter: blur(20px);
    transform: rotate(-42deg);
    transition: all 1s;
}

a.btn-green.btnnews {
    position: relative;
}

a.btn-green.btnnews:hover:after {
    right: -30px;
    left: 100%;
}

.Toastify__toast {
    padding: 30px 10px !important;
}

.Toastify__toast-container {
    z-index: 9999999999 !important;
}

.comboprice.combopriceMargin {
    margin-top: -24px;
    position: relative;
}

.Toastify {
    position: relative !important;
    z-index: 99999999 !important;
}